import React, { useState, useEffect } from "react";

import { Container } from "@mui/material";
import { useLazyGetCmsQuery } from "../../services/cms";
import { CmsResponse } from "../../types/General";
import useTranslation from "../../hooks/Translation";

function Support() {
  const [cmsData, setCmsData] = useState<CmsResponse>();
  const translation = useTranslation() as any;
  const [getCms, { isLoading }] = useLazyGetCmsQuery();
  const fetchCmsData = async () => {
    try {
      const response = await getCms({}).unwrap();

      if (response?.statusCode === 200) {
        setCmsData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    fetchCmsData();
  }, []);
  return (
    <div>
      <div className="Terms">
        <figure className="img-figure">
          <img
            className="logo-png"
            src="/static/images/logo.png"
            alt=""
            height={100}
            width={300}
          />
        </figure>
      </div>
      <h2 className="Terms-heading">{translation.about_us.support}</h2>
      <Container maxWidth="lg">
        <p
          className={cmsData ? "about_txt" : "about_txt2"}
          dangerouslySetInnerHTML={{
            __html: cmsData ? cmsData?.support : translation.about_us.msg,
          }}
        />
      </Container>
    </div>
  );
}

export default Support;
