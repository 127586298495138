import { ChangeEvent, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import { showError, showToast } from "../../constants";
import { useGenerateTicketMutation } from "../../services/main";
import useTranslation from "../../hooks/Translation";

const GenerateTicket = () => {
  const navigate = useNavigate();
  const [ticket] = useGenerateTicketMutation();
  const translation = useTranslation() as any;
  const formik = useFormik({
    initialValues: {
      name: "",
      message: "",
      email: "",
      type: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(translation.help_support.name),
      type: Yup.string()
        .min(2, translation.help_support.min2)
        .max(10, translation.help_support.max10)
        .required(translation.help_support.type),
      message: Yup.string()
        .min(2, translation.help_support.min2)
        .max(80, translation.help_support.max80)
        .required(translation.help_support.msg),
      email: Yup.string()
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.help_support.enter_valid_email
        )

        .required(translation.manage_advertisements.field_req),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        userName: values.name,
        description: values.message,
        issueType: values.type,
        email: values.email,
        appKey: new Date().toISOString(),
      };
      console.log(body, "body");

      try {
        const response: any = await ticket({
          body,
        });
        if (response?.data?.statusCode === 200) {
          showToast(translation.help_support.ticket_toast);
          navigate("/help-and-support");
        }
      } catch (error: any) {
        showError(error?.data?.message);
      }
    },
  });
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.help_support.ticket}</h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/help-and-support");
            }}
          >
            {translation.help_support.back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.help_support.Name}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.help_support.Name}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.name && formik.errors.name}
                      </h6>
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.help_support.mail}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.help_support.mail}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.email && formik.errors.email}
                      </h6>
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.help_support.issue}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="type"
                    variant="outlined"
                    fullWidth
                    placeholder={translation.help_support.issue}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.type && formik.errors.type}
                      </h6>
                    }
                  />
                </Grid>
                <Grid className="message-box" item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.help_support.Message}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="message"
                    variant="outlined"
                    multiline
                    minRows={4}
                    maxRows={4}
                    fullWidth
                    placeholder={translation.help_support.Message}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.message && formik.errors.message}
                      </h6>
                    }
                  />
                </Grid>

                {/* <Grid item xs={4}>
              <Typography className="custom_label">
                Start Date
              </Typography>
              <TextField
                className="text_field"
                hiddenLabel
                type={"date"}
                name="startDate"
                variant="outlined"
                fullWidth
                id="startDate"
                // value={formik.values.startDate}
                // onChange={formik.handleChange}
                // helperText={
                //   formik.touched.startDate && formik.errors.startDate
                // }
              />
            </Grid> */}

                {/* <Grid item xs={4}>
              <Typography className="custom_label">End Date</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                type={"date"}
                name="endDate"
                variant="outlined"
                fullWidth
                id="endDate"
                // value={formik.values.endDate}
                // onChange={formik.handleChange}
                // helperText={
                //   formik.touched.endDate && formik.errors.endDate
                // }
              />
            </Grid> */}
                <br />
              </Grid>

              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  // onClick={() => setError(true)}
                >
                  {translation.help_support.submit}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};
export default GenerateTicket;
