import LoginPage from "./auth/login";
import SignUpPage from "./auth/signUp";
import DashBoardPage from "./dashBoard";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Faq from "./faq";
import Settings from "./settings";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";

import RecievedNotifications from "./manageNotification/receivedNotification";

import ChangePassword from "./auth/changePassword";

import ProfileSetup from "./profileSetup";

import ManageAdvertisements from "./manageAdvertisements";
import AdvertisementDetails from "./manageAdvertisements/details";
import AddAdvertisement from "./manageAdvertisements/add";
import CustomerSupport from "./helpAndSupport";
import QueryDetails from "./helpAndSupport/details";
import GenerateTicket from "./helpAndSupport/add";
import Analytics from "./analytics";
import TermsAndCondition from "./terms/index";
import AboutUs from "./aboutus/index";
import Support from "./support";
import PrivacyPolicy from "./privacy/index";
import PaymentSuccess from "./manageAdvertisements/paymentSucess";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  SignUpPage,
  DashBoardPage,
  ForgotPasswordPage,
  VerifyOtp,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,

  Settings,
  ManageNotifications,
  AddNotification,

  RecievedNotifications,

  ChangePassword,

  ProfileSetup,

  ManageAdvertisements,
  AdvertisementDetails,
  AddAdvertisement,
  CustomerSupport,
  QueryDetails,
  GenerateTicket,
  TermsAndCondition,
  Analytics,
  AboutUs,
  PrivacyPolicy,
  PaymentSuccess,
  Support,
};
